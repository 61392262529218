export default {
	display: ['flex', 'inline-flex'],
	children: ['+1', '+3', '+5', '+7', '-1', '-3', '-5', '-7'],
	flexDirection: ['row', 'row-reverse', 'column', 'column-reverse'],
	flexWrap: ['nowrap', 'wrap', 'wrap-reverse'],
	justifyContent: [
		'flex-start',
		'flex-end',
		'center',
		'space-between',
		'space-around',
		'space-evenly',
	],
	alignItems: ['stretch', 'flex-start', 'flex-end', 'center', 'baseline'],
	alignContent: [
		'stretch',
		'flex-start',
		'flex-end',
		'center',
		'space-between',
		'space-around',
		'space-evenly',
	],
	order: ['+1', '+3', '+5', '+7', '-1', '-3', '-5', '-7'],
	flexBasis: ['auto', '20%', '40%', '60%', '80%', '100%'],
	flexGrow: ['+1', '+3', '+5', '+7', '-1', '-3', '-5', '-7'],
	flexShrink: ['+1', '+3', '+5', '+7', '-1', '-3', '-5', '-7'],
	alignSelf: [
		'auto',
		'stretch',
		'flex-start',
		'flex-end',
		'center',
		'baseline',
	],
};
